/**
 * @flow
 */

import * as React from 'react';
import Page from 'src/components/Page';
import InternalLink from 'src/components/navigation/InternalLink';
import ExternalLink from 'src/components/navigation/ExternalLink';
import {linkStyle} from 'src/styles/common';
import {getWithBreakpoints} from 'src/styles/breakpoints';
import SoutherfieldsImageryIcon from 'src/components/icons/SoutherfieldsImageryIcon';
import SoutherfieldsMusicIcon from 'src/components/icons/SoutherfieldsMusicIcon';
import SoutherfieldsContactIcon from 'src/components/icons/SoutherfieldsContactIcon';
import SoutherfieldsShopIcon from 'src/components/icons/SoutherfieldsShopIcon';
import {ETSY_SHOP_URL, DEFAULT_OG_IMAGE} from 'src/constants';

// 540 is just a nice looking width at which to have the icons links switch from
// landscape to portrait
const withHomeBreakpoints = getWithBreakpoints([540]);
const BIG_ICON_SIZE = 200;
const BAR_SPACING = 100;
const HALF_BAR_SPACING = BAR_SPACING / 2;
const styles = {
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  bigIconLinkWrapper: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: HALF_BAR_SPACING,
    marginBottom: HALF_BAR_SPACING,
    width: '100%',
    ':first-of-type': {
      paddingTop: 0,
    },
    ':last-of-type': {
      marginBottom: 0,
    },
  },
  bigIconLinkRoot: (theme) =>
    withHomeBreakpoints({
      ...linkStyle(theme),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ':hover svg': {
        color: theme.linkHoverColor,
      },
    }),
  icon: (theme) => ({
    height: BIG_ICON_SIZE,
    width: BIG_ICON_SIZE,
    margin: 8,
    color: theme.fontColor,
  }),
  label: (theme) => ({
    fontSize: `${theme.fontSizeHuge}rem`,
  }),
};

type BigIconLinkInfo = $ReadOnly<{
  Icon: React.ComponentType<{}>,
  route: string,
  label: string,
  isExternal: boolean,
}>;
const BIG_ICON_LINK_INFOS: $ReadOnlyArray<BigIconLinkInfo> = [
  {
    label: 'Imagery',
    route: '/imagery/',
    Icon: SoutherfieldsImageryIcon,
    isExternal: false,
  },
  {
    label: 'Music',
    route: '/music/',
    Icon: SoutherfieldsMusicIcon,
    isExternal: false,
  },
  {
    label: 'Shop',
    route: ETSY_SHOP_URL,
    Icon: SoutherfieldsShopIcon,
    isExternal: true,
  },
  {
    label: 'Contact',
    route: '/contact/',
    Icon: SoutherfieldsContactIcon,
    isExternal: false,
  },
];

type BigIconLinkProps = $ReadOnly<{
  info: BigIconLinkInfo,
  labelFirst: boolean,
}>;
function BigIconLink(props: BigIconLinkProps): React.Node {
  const {
    info: {Icon, route, label, isExternal},
    labelFirst = true,
  } = props;
  const finalRootStyle = [
    styles.bigIconLinkRoot,
    withHomeBreakpoints({
      flexDirection: ['column-reverse', labelFirst ? 'row' : 'row-reverse'],
    }),
  ];
  const children = (
    <React.Fragment>
      <div css={styles.label}>{label}</div>
      <div css={styles.icon}>
        <Icon />
      </div>
    </React.Fragment>
  );
  return isExternal ? (
    <ExternalLink css={finalRootStyle} href={route}>
      {children}
    </ExternalLink>
  ) : (
    <InternalLink css={finalRootStyle} to={route}>
      {children}
    </InternalLink>
  );
}

export default function IndexPage(): React.Node {
  return (
    <Page
      title='Home'
      canonicalPath=''
      ogImage={DEFAULT_OG_IMAGE}
      description='Southerfields home page for music, painting, photography, and other arts.'
      verticallyCenterContent={true}
    >
      <article css={styles.root}>
        {BIG_ICON_LINK_INFOS.map((info, idx) => (
          <div key={info.label} css={styles.bigIconLinkWrapper}>
            <BigIconLink info={info} labelFirst={idx % 2 === 0} />
          </div>
        ))}
      </article>
    </Page>
  );
}
