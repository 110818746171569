/**
 * @flow
 */

import * as React from 'react';

type Props = $ReadOnly<{}>;

export default function SoutherfieldsMusicIcon(props: Props): React.Node {
  const commonProps = {
    stroke: 'currentColor',
    fill: 'currentColor',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    opacity: 1,
    strokeWidth: 2,
  };
  return (
    <svg height='100%' width='100%' viewBox='0 0 100 100'>
      <path
        {...commonProps}
        d='M59.8828+4.64985L52.6734+1.73292'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M42.7549+12.5238C38.4557+15.4321+38.4135+20.7955+40.2681+24.2833C42.1226+27.7712+47.8654+27.8871+50.721+25.8428C53.5766+23.7986+54.1772+20.0579+52.3227+16.5701C50.4681+13.0823+47.0541+9.61552+42.7549+12.5238Z'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M12.79+69.2397C12.79+69.2397+20.988+57.5228+23.4174+50.6285C25.1733+45.6454+27.9157+36.5831+29.4343+33.261C30.6287+30.6483+35.9701+26.3661+39.471+22.6042'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M48.1349+64.8742C53.7537+65.9069+64.7246+69.8851+70.6102+69.0048C74.0393+68.492+81.0433+59.5985+81.5167+61.9179C82.0185+64.3769+82.8716+69.6462+82.42+71.2521C81.0126+76.2561+68.5993+78.283+60.0179+82.0468C57.3381+83.2221+51.8403+81.7156+45.5836+83.462C39.327+85.2084+31.6125+89.2934+31.6125+89.2934'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M53.325+18.253L59.6566+4.47335L83.6393+6.74939L78.7469+22.4415'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M56.8805+11.6494C60.6995+10.8941+64.7024+10.6862+68.2172+11.3812C76.6172+13.0422+75.7629+28.371+78.1833+32.7845C79.2502+34.73+82.1106+37.5834+85.2069+40.2828C87.0578+41.8963+74.7663+39.2862+72.5313+40.6073C70.2962+41.9283+69.2138+49.537+68.7392+53.0013C68.2647+56.4657+66.3664+59.5505+66.3664+59.5505'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M48.6606+11.7311L52.7863+1.39648L80.5624+3.33533L83.4286+6.74939'
        fillOpacity='0'
      />
      <path {...commonProps} d='M79.0451+6.4965L75.98+15.938' fillOpacity='0' />
      <path
        {...commonProps}
        d='M77.9071+32.9239L62.1012+32.671C62.1012+32.671+69.1295+38.8352+71.2897+41.2272C73.7196+38.7029+78.1406+39.7782+84.5142+40.1198C79.5774+37.3864+77.9071+32.9239+77.9071+32.9239Z'
      />
      <path
        {...commonProps}
        d='M59.6987+19.5627C60.3626+18.5722+62.3258+19.6326+64.4194+20.7428C67.2012+22.218+66.3161+24.4098+66.4847+26.6858C66.6013+28.2597+62.5649+25.7164+61.5111+24.0726C60.4056+22.348+59.0349+20.5532+59.6987+19.5627Z'
      />
      <path
        {...commonProps}
        d='M55.2157+82.863C55.2681+86.8742+55.5543+90.104+56.2488+91.3386C58.5453+95.4214+60.4592+99.8232+63.1385+98.2922C65.8179+96.7611+75.3121+90.676+73.7284+86.6585C72.2859+82.9993+76.8568+77.5299+76.6654+76.3178'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M44.8934+27.6084C44.8934+27.6084+43.5102+34.2871+43.7654+36.2647C44.0205+38.2423+47.0624+41.1965+48.5297+43.5569C49.997+45.9173+56.7591+53.7002+56.4401+57.5278C56.3373+58.7621+56.042+62.2918+55.7662+66.645'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M75.3878+65.8534L78.081+58.662L87.21+58.4268L82.8719+69.1277L81.4973+59.9338L75.3878+65.8534Z'
      />
      <path
        {...commonProps}
        d='M76.9922+27.5421C78.9861+25.4088+79.2397+22.1688+77.6195+19.1218C76.5267+17.0665+74.8922+15.0185+72.8543+14.278'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M43.64+27.2338C46.3375+29.3412+51.3216+29.5941+54.1772+26.8544C57.0328+24.1147+56.5376+21.217+55.3574+17.4552C55.1499+16.7937+54.8785+16.2045+54.5597+15.682'
        fillOpacity='0'
      />
    </svg>
  );
}
