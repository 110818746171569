/**
 * @flow
 */

import * as React from 'react';

type Props = $ReadOnly<{}>;

export default function SoutherfieldsContactIcon(props: Props): React.Node {
  const commonProps = {
    stroke: 'currentColor',
    fill: 'currentColor',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    opacity: 1,
    strokeWidth: 2,
  };
  return (
    <svg
      height='100%'
      width='100%'
      viewBox='0 0 100 100'
      style={{transform: 'scale(-1, 1)'}}
    >
      <path
        {...commonProps}
        d='M75.9279+71.2116C70.7719+65.744+58.5378+56.4705+55.3038+49.3411C53.4196+45.1875+58.2363+31.0962+55.3364+32.231C52.262+33.4341+45.8327+36.2576+44.3819+37.9041C39.8612+43.0345+46.4938+58.1927+48.4698+70.3601C49.0868+74.1597+54.6798+79.155+57.2224+87.3131C57.4867+88.1611+57.7343+89.0446+57.9659+89.947'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M29.5201+91.173C29.8019+87.0299+27.3565+63.5786+26.761+61.5157C26.761+61.5157+25.8947+56.8303+26.9554+52.3544C28.0162+47.8785+30.2965+38.0292+28.1806+35.3482C26.0647+32.6672+9.74493+30.0405+12.7812+28.9385C17.8606+27.0948+22.7237+24.9502+24.9555+23.069C30.0185+18.8014+36.2827-0.641126+47.3348+1.5333C72.9234+6.56769+76.2918+27.0556+76.9736+28.7593C79.485+35.0356+84.0081+42.8231+84.2346+46.5955C84.5225+51.3919+83.5894+63.8122+83.3807+70.7592C83.0919+80.3708+87.5941+98.6357+87.5941+98.6357'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M25.2276+23.1476L44.7416+30.3334C44.7416+30.3334+33.1942+34.5638+29.4093+36.4743C27.2028+31.686+20.4945+31.1551+14.7031+29.2247C19.9153+27.5838+25.2276+23.1476+25.2276+23.1476Z'
      />
      <path
        {...commonProps}
        d='M53.9063+15.3887C53.5615+13.8585+50.6496+14.2286+47.5542+14.5979C43.4412+15.0887+43.4878+18.1978+42.2015+20.9106C41.312+22.7865+47.471+21.5801+49.5435+20.0629C51.7179+18.4711+54.2511+16.9189+53.9063+15.3887Z'
      />
      <path
        {...commonProps}
        d='M45.7625+36.7428L45.3+33.3892L64.211+23.4962L67.2089+36.3395L55.5455+41.9622L57.325+30.4569L45.7625+36.7428Z'
      />
    </svg>
  );
}
