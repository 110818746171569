/**
 * @flow
 */

import * as React from 'react';

type Props = $ReadOnly<{}>;

export default function SoutherfieldsImageryIcon(props: Props): React.Node {
  const commonProps = {
    stroke: 'currentColor',
    fill: 'currentColor',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    opacity: 1,
    strokeWidth: 2,
  };
  return (
    <svg height='100%' width='100%' viewBox='0 0 100 100'>
      <path
        {...commonProps}
        d='M47.5872+62.8593C47.5872+62.8593+46.524+59.3346+46.9585+55.8069C47.393+52.2793+48.3079+44.5214+46.4552+42.6421C44.6025+40.7628+31.8458+40.1314+34.0841+39.0271C37.8285+37.1798+41.3813+35.1198+42.9356+33.4855C46.4615+29.778+49.6213+14.3141+58.294+15.0455C67.4548+18.5061+71.0785+20.699+77.4334+23.658C81.6351+28.1321+83.0149+32.7758+83.3688+33.4381C85.8306+38.045+89.9658+43.6417+90.46+46.5197C91.0884+50.1789+91.4266+59.797+91.8563+65.1501C92.4508+72.5563+97.4596+86.2015+97.4596+86.2015'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M62.9288+68.5611C57.1353+68.9558+45.597+71.7077+39.7547+70.1397C36.3509+69.2262+30.3036+59.4368+29.558+61.7245C28.7675+64.1499+27.2981+69.3729+27.5689+71.0468C28.413+76.2626+40.7151+79.7416+48.9472+84.5326C51.5179+86.0287+57.2439+85.1415+63.3609+87.6269C63.9968+87.8853+71.7986+91.7506+79.1275+98.6644'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M43.1512+33.5228L58.7482+37.3843C58.7482+37.3843+50.2391+41.6139+47.4945+43.4026C45.3933+39.9125+40.1961+40.0745+35.5844+39.0837C39.4481+37.3809+43.1512+33.5228+43.1512+33.5228Z'
      />
      <path
        {...commonProps}
        d='M64.5176+25.1284C64.1228+23.9825+61.9179+24.514+59.572+25.0605C56.4549+25.7867+56.7547+28.1706+55.9972+30.3633C55.4734+31.8795+60.1011+30.4299+61.5639+29.0887C63.0986+27.6816+64.9123+26.2743+64.5176+25.1284Z'
      />
      <path
        {...commonProps}
        d='M48.2944+22.693C46.1978+21.8944+34.0177+15.1055+36.5137+12.2102C39.0096+9.31493+60.175+7.6177+70.4581+10.7126C80.7413+13.8076+98.4124+23.3919+93.9198+25.3886C89.4271+27.3854+86.3749+30.2284+81.6826+29.5295'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M57.8694+8.69302C60.0727+6.06601+63.6516+1.60123+66.2786+1.347C68.9056+1.09278+74.0748+5.1604+78.9899+5.92308C80.1391+6.1014+83.4146+6.36999+84.1592+7.19421C86.599+9.89513+84.763+15.1495+84.6981+16.578'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M36.304+67.492C38.295+67.5774+39.8173+67.5393+39.9222+67.2446C40.0846+66.7885+36.2102+65.1876+33.0566+63.987'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M28.8366+62.481C27.7064+62.2031+19.6499+62.0947+19.0585+61.397C18.467+60.6993+20.0476+56.4815+19.3156+55.6724C18.5835+54.8633+8.37088+53.3378+5.51141+52.9025C4.28728+52.7162+6.87561+55.9752+6.57308+57.2232C6.27056+58.4712+4.39845+60.1689+3.80467+61.4379C3.21089+62.7068+5.40932+63.1676+5.31767+64.5827C5.22601+65.9979+0.958016+68.1771+3.18113+68.52C5.25617+68.8401+13.3381+70.1158+15.2781+70.4404C17.2182+70.765+17.0935+65.4932+18.3592+64.7339C19.6248+63.9746+25.0704+66.3452+26.4526+66.5939C26.6847+66.6357+27.2103+66.7054+27.9256+66.7886'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M13.7034+69.7415L17.2988+55.1268L13.7118+54.2522L10.9269+69.6925L13.7034+69.7415Z'
        fillOpacity='0'
      />
    </svg>
  );
}
