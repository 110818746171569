/**
 * @flow
 */

import * as React from 'react';

type Props = $ReadOnly<{}>;

export default function SoutherfieldsShopIcon(props: Props): React.Node {
  const commonProps = {
    stroke: 'currentColor',
    fill: 'currentColor',
    strokeLinejoin: 'round',
    strokeLinecap: 'round',
    opacity: 1,
    strokeWidth: 2,
  };
  return (
    <svg height='100%' width='100%' viewBox='0 0 100 100'>
      <path
        {...commonProps}
        d='M83.7371+49.1184C80.9734+49.6856+75.9615+49.7516+72.1837+50.7438C70.8684+51.0893+69.7027+51.5471+68.8335+52.1774C66.8727+53.5994+65.0161+63.0608+64.091+61.2596C63.1101+59.3499+61.1443+55.1934+60.9813+53.7205C60.4734+49.131+67.525+43.841+71.7529+38.2095C73.0731+36.4509+76.8547+36.1173+80.2104+32.8453C80.5592+32.5052+80.9107+32.1394+81.2607+31.7569'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        fillOpacity='0'
        d='M69.1707+53.0562L81.7641+57.5829'
      />
      <path
        {...commonProps}
        d='M65.9799+58.5662L74.2747+61.4217L25.1776+71.3397L2.02731+61.5612L6.31313+60.4899'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        fillOpacity='0'
        d='M53.7569+54.3585L61.8196+57.134'
      />
      <path
        {...commonProps}
        d='M25.4025+71.3757L25.2986+86.7654L73.0274+82.2941L74.7951+61.8092'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        fillOpacity='0'
        d='M2.00607+61.7052L3.87779+81.1502L25.2286+86.6918'
      />
      <path
        {...commonProps}
        d='M55.8527+16.8273L69.0384+21.6828C69.0384+21.6828+61.2358+24.5413+58.6783+25.8322C57.1874+22.5967+52.6545+22.238+48.7413+20.9336C52.2632+19.8249+55.8527+16.8273+55.8527+16.8273Z'
      />
      <path
        {...commonProps}
        d='M75.231+11.5846C74.998+10.5507+73.0304+10.8007+70.9389+11.0503C68.1597+11.3819+68.1912+13.4828+67.322+15.3158C66.721+16.5833+70.8826+15.7682+72.283+14.743C73.7522+13.6674+75.4639+12.6186+75.231+11.5846Z'
      />
      <path
        {...commonProps}
        fillOpacity='0'
        d='M58.5564+55.9268C58.041+50.4799+57.1516+43.6631+56.8888+42.7527C56.8888+42.7527+56.3035+39.5868+57.0202+36.5624C57.737+33.538+59.2778+26.8829+57.8481+25.0713C56.4183+23.2597+45.3911+21.4849+47.4427+20.7402C50.8748+19.4944+54.1609+18.0454+55.6689+16.7742C59.0899+13.8906+63.3227+0.753236+70.7906+2.2225C88.0809+5.62425+90.3569+19.4679+90.8176+20.6192C92.5146+24.8601+95.5709+30.1221+95.7239+32.6711C95.9184+35.912+95.2879+44.3045+95.1469+48.9986C94.9518+55.4932+97.9939+67.8348+97.9939+67.8348'
      />
      <path
        {...commonProps}
        d='M16.9798+85.1017L10.9487+88.8451L33.6173+94.3563L69.8533+82.7247'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M40.5843+93.6284L37.8807+96.332L42.04+97.8917L44.6396+95.2921L40.5843+93.6284Z'
      />
      <path
        {...commonProps}
        d='M67.8282+83.7499L65.7485+86.6614L70.5317+88.8451L73.0274+85.7256L67.8282+83.7499Z'
      />
      <path
        {...commonProps}
        fillOpacity='0'
        d='M53.3707+65.5258L54.0607+36.3151L17.5996+26.7709L5.61802+63.0229'
      />
      <path
        {...commonProps}
        d='M34.8292+68.8965L34.5937+60.2791L43.1212+52.6697'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M38.4595+56.3936L40.1461+44.8101L46.125+34.6836'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        fillOpacity='0'
        d='M22.791+37.602C22.2327+38.8884+22.2457+40.8813+25.0262+41.9126C26.0427+42.2897+28.602+42.2393+29.2205+39.3469C29.7753+36.7524+28.4985+35.3747+26.9468+35.0133C24.791+34.5112+23.5875+35.7664+22.791+37.602Z'
      />
      <path
        {...commonProps}
        d='M28.8618+55.5544C28.7318+55.8918+26.3743+60.1011+28.916+58.9151C30.6609+58.1008+35.293+46.587+32.994+45.9615C32.6052+45.8557+32.1281+46.5548+32.0449+46.8605C31.145+50.1681+30.1005+52.3395+28.8618+55.5544Z'
      />
      <path
        {...commonProps}
        d='M12.1777+64.1765C11.8739+62.4138+19.1435+51.8092+20.993+52.7967C21.1035+52.8556+21.0882+53.1699+21.0738+53.2649C20.7171+55.6074+19.6878+60.1143+18.5365+62.0439C17.6527+63.5251+17.23+65.7399+15.9799+66.5227L12.1777+64.1765Z'
      />
      <path
        {...commonProps}
        d='M74.4857+61.2984L81.8537+57.6784'
        fillOpacity='0'
      />
      <path {...commonProps} d='M56.7105+39.7681L53.9396+43.4516' />
      <path
        {...commonProps}
        d='M57.5007+47.1449L53.9394+49.088'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M55.9046+48.2807L61.3285+50.2789'
        fillOpacity='0'
      />
      <path
        {...commonProps}
        d='M14.1934+87.798L12.1943+89.9265L15.6213+92.055L16.9921+89.5631L14.1934+87.798Z'
      />
    </svg>
  );
}
